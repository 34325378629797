"use client"

import React from 'react'
import { useRouter } from 'next/navigation'
import Image from 'next/image'

import { useAppDispatch } from '@/redux/store'
import { getSalesGroup } from '@/redux/slices/masterdataSlice'

import { Layout, Typography, Avatar, Space, Popover, Button, Tour } from 'antd'
import type { TourProps } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useSession, signOut } from "next-auth/react"

import ModelCostCenter from './ModelCostCenter'

type Props = {}

const { Header } = Layout
const { Title, Text } = Typography

const ComponentHeader = (props: Props) => {
  const ref1 = React.useRef(null)
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { data: session } = useSession()

  const [openTour, setOpenTour] = React.useState<boolean>(false)
  const [openPopover, setOpenPopover] = React.useState<boolean>(false)
  const [openCostCenter, setOpenCostCenter] = React.useState<boolean>(false)
  const [evaDepartment, setEvaDepartment] = React.useState<string>("")

  React.useEffect(() => {
    const isz_department = localStorage.getItem("isz_department") || ""
    setEvaDepartment(isz_department)

    if (session) {
      setOpenTour(isz_department ? false : true)
    }
  }, [session])

  // Tour
  const stepsTour: TourProps['steps'] = [
    {
      title: 'Please choose your Department.',
      description: 'To access all features, please select your department first.',
      target: () => ref1.current,
    }
  ]

  // Finish Tour
  const FinishTour = async () => {
    await setOpenTour(false)
  }

  // Popover Open/Close
  const handleChangePopover = async (newOpen: boolean) => await setOpenPopover(newOpen)

  // Auth Sign Out
  const handleSignOut = async () => {
    await localStorage.removeItem("isz_comp_code")
    await localStorage.removeItem("isz_cost_center")
    await localStorage.removeItem("isz_department")
    await localStorage.removeItem("isz_division")
    await localStorage.removeItem("isz_sales_group")
    await signOut()
  }

  // Handle choose cost center
  const handleCostCenter = async () => {
    dispatch(getSalesGroup())
    await setOpenCostCenter(true)
    await setOpenPopover(false)
  }

  const handleCostCenterClose = async () => {
    const isz_department = await localStorage.getItem("isz_department") || ""
    await setEvaDepartment(isz_department)

    await setOpenCostCenter(false)

    router.refresh()
  }

  return (
    <Header className='header'>
      <Image
        src={"/ispace.svg"}
        alt={"ispacezone"}
        width='180'
        height='40'
        priority={true}
      />

      <Space wrap align='center'>
        <div className='nomobile'>
          <Space>
            <Space.Compact direction="vertical" style={{ verticalAlign: 'middle' }}>
              <Text strong>{`Dept. : ${evaDepartment}`}</Text>
              {session && <Button className='color-primary' ref={ref1} onClick={handleCostCenter}>Department</Button>}
            </Space.Compact>
          </Space>
        </div>

        {/* {session ? <Title level={5} className='title-non-margin'>| {session.user?.name}</Title> : <Title level={5}>| Guest</Title>} */}
        {session ? <Popover
          content={<Space direction="vertical" size="small" style={{ display: 'flex', textAlign: 'center' }}><Title level={5} className='title-non-margin'>{session.user?.name}</Title><div className='showmobile'><Text strong>{`Dept. : ${evaDepartment}`}</Text><br></br>{session && <Button className='color-primary' onClick={handleCostCenter}>Department</Button>}</div><Button type="primary" danger onClick={handleSignOut}>Log Out</Button></Space>}
          title=""
          trigger="click"
          open={openPopover}
          onOpenChange={handleChangePopover}
        ><Avatar size={64} src={session.user?.image} style={{ cursor: 'pointer' }} /></Popover> : <Avatar size={64} icon={<UserOutlined />} />}
      </Space>

      <ModelCostCenter stateOpen={openCostCenter} onModelClose={handleCostCenterClose} />

      <Tour open={openTour} onClose={FinishTour} steps={stepsTour} />
    </Header>
  )
}

export default ComponentHeader